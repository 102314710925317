.card {
  border: 1px solid #f3f3f3;
  padding: 30px;
  width: 220px;
  border-radius: 40px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.06);
    transform: translateY(-5px);
  }
  &__title {
    flex: 1 1 100%;
  }

  &__image {
    align-self: center;
    position: relative;
    left: 25px;
  }


  &__favorite {
    position: absolute;
    cursor: pointer;
    background-color: #fff;
    z-index: 2;
    outline: none;
    border:none;
  }
  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__price {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 13px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  b {
    font-size: 14px;
  }

  h5 {
    font-weight: 400;
    font-size: 14px;
  }
}

