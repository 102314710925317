.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  @media (max-width: 479.98px){
    flex-direction: column;
  }

  &__label {
    display: flex;
    align-items: center;
    @media (max-width: 479.98px){
      margin-bottom: 40px;
    }
    @media (any-hover: hover) {
      &:hover {
      .header__image {
        transform: scale(1.1);
      }
     }
    }
  }

  &__image {
    transition: transform 0.3s ease 0s;
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__logo {
    text-transform: uppercase;
  }

  &__description {
    opacity: 0.6;
  }

  &__list {
    display: flex;

    li {
      cursor: pointer;
    }
  }

  &__cart {
    margin-right: 30px;

    img {
      transition: all 0.3s ease 0s;
      will-change: transform;
    }

    @media (any-hover: hover) {
      &:hover {
        img {
          transform: scale(1.3);
        }
      }
    }
  }
}