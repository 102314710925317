.favorites {
  padding: 40px;
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  &__search {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 0 15px;
    display: flex;
    input {
      border: 0;
      padding: 13px;
      font-size: 16px;
      width: 200px;
    }
    @media (max-width: 991.98px){
      display: none;
    }
  }
  &__empty {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 100px;
    }
  }
  &__button {
    padding: 5px 20px;
    margin-left: 20px;
    height: 45px;
    display: inline-block;
    width: 350px;
    @media (max-width: 479.98px){
      width: 250px;
      height: 65px;
      margin-left: 0px;
    }
  }
}