body {
  overflow-x: hidden;
  * {
    box-sizing: border-box;
    outline: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7f6ff;

  .lock & {
    overflow: hidden;
    touch-action: none;
    padding-right: 17px;
  }
}

* {
  font-family: 'Inter', system-ui;
}

.wrapper {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  max-width: 1080px;
  margin: 50px auto;
}

header {
  border-bottom: 1px solid #eaeaea;

  img {
    margin-right: 15px;
  }

  h3,
  p {
    margin: 0;
  }
}

.content {
  h1 {
    margin: 0;
  }
}

.button {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 8px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;

  &:hover {
    background: #eee
  }

}

//.search-block {
//  border: 1px solid #f3f3f3;
//  border-radius: 10px;
//  padding: 0 15px;
//
//  input {
//    border: 0;
//    padding: 13px;
//    font-size: 16px;
//    width: 200px;
//  }
//}

//.overlay {
//  position: absolute;
//  left: 0;
//  top: 0;
//  width: 100%;
//  height: 100%;
//  background-color: rgba(0, 0, 0, 0.5);
//  z-index: 1;
//  opacity: 0;
//  visibility: hidden;
//  transition: all 0.5s;
//  overflow: hidden;
//  &__active {
//    opacity: 1;
//    visibility: visible;
//  }
//}

//.drawer {
//  display: flex;
//  flex-direction: column;
//  position: absolute;
//  width: 420px;
//  height: 100%;
//  right: 0;
//  background: #ffffff;
//  box-shadow: -10px 4px 24px rgba(0, 0, 0, 0.1);
//  padding: 30px;
//  transition: all 0.5s;
//  transform: translateX(451px);
//
//  &__active {
//    transform: translateX(0);
//  }
//
//  .items {
//    flex: 1;
//    overflow: auto;
//    margin-bottom: 40px;
//  }
//
//}
//
//.cartTotalBlock {
//  ul {
//    display: block;
//    margin-bottom: 40px !important;
//
//    li {
//      display: flex;
//      align-items: flex-end;
//      margin-bottom: 20px;
//
//      div {
//        flex: 1;
//        height: 1px;
//        border-bottom: 1px dashed #dfdfdf;
//        position: relative;
//        top: -4px;
//        margin: 0 7px;
//      }
//    }
//  }

//  .blueButton {
//    position: relative;
//
//    &:hover {
//      img {
//        transform: translateX(5px);
//      }
//    }
//
//    img {
//      position: absolute;
//      right: 30px;
//      top: 20px;
//      transition: transform 0.15s ease-in-out;
//    }
//  }
//}

.blueButton {
  width: 100%;
  height: 55px;
  background: #318CE7;
  border-radius: 18px;
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
  img {
    transition: all 0.3s ease 0s;
  }
  &:disabled {
    background: rgba(70, 73, 77, 0.23);
    img{
      opacity: 0;
    }
  }
  &:hover {
    background: lighten(#2A4EC4, 5%);
    img {
      transform: translate(50%, 0px);
    }
  }

}

.cartItem {
  border: 1px solid #f3f3f3;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;

  .cartItemImg {
    height: 70px;
    width: 70px;
    background-size: contain;
    background-position: 0 -3px;
    background-repeat: no-repeat;
    margin-right: 20px;
    //background: url("assets/img/sneakers/1.jpg") center / cover no-repeat;
  }

  p {
    font-size: 16px;
    margin: 0;
  }

  b {
    font-size: 14px;
  }

  .removeBtn {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}

.list__items {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: center;
}

.icon {
  transition: all 0.3s;
}

.icon:hover {
  transform: scale(1.3);
  will-change: transform;
}


.bg {
  position: fixed;
  width: 250px;
  z-index: 1;

  @media (max-width: 1450px) {
    width: 150px;
  }
  img {
    width: 100%;
    height: 100%;
  }

}


.bg1 {
  bottom: 0;
  left: 0;
  transition: all 0.5s ease-in-out 0.5s;
  transform: translate(-105%, 0px);
  @media (max-width: 1300px) {
    display: none;
  }
  &.active {
    transform: translate(0%, 0px);
  }
}

.bg2 {
  bottom: 0;
  transition: all 0.5s ease-in-out 1.5s;
  transform: translate(105%, 0px);
  left: calc(100vw - 250px);
  @media (max-width: 1300px) {
    display: none;
  }
  &.active {
    transform: translate(0%, 0px);
  }
}

.bg3 {
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out 2.5s;
  transform: rotate(-270deg) translate(60px, 300px);
  @media (max-width: 1160px) {
    display: none;
  }
  &.active {
    transform: rotate(-270deg) translate(60px, 47px);
  }
}
