.cart {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  overflow: hidden;
  z-index: 10;

  &__active {
    opacity: 1;
    visibility: visible;
  }
  &__close {
    cursor: pointer;
  }

  &__total {
    ul {
      display: block;
      margin-bottom: 40px !important;

      li {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;

        div {
          flex: 1;
          height: 1px;
          border-bottom: 1px dashed #dfdfdf;
          position: relative;
          top: -4px;
          margin: 0 7px;
        }
      }
    }
  }
}

.drawer {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 420px;
  height: 100%;
  right: 0;
  background: #ffffff;
  box-shadow: -10px 4px 24px rgb(0 0 0 / 10%);
  padding: 30px;
  transition: all 0.5s;
  transform: translateX(451px);
  @media (max-width: 479.98px){
    width: 300px;
  }

  &__active {
    transform: translateX(0);
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__items {
    flex: 1;
    overflow: auto;
    margin-bottom: 40px;
  }

  &__item {
    border: 1px solid #f3f3f3;
    border-radius: 20px;
    overflow: hidden;
    padding: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-image {
      height: 70px;
      width: 70px;
      background-size: contain;
      background-position: 0 -3px;
      background-repeat: no-repeat;
      margin-right: 20px;
    }
  }
}

.item-drawer {

  &__label {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    flex: 1 1;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__remove {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}
